<template>
  <b-modal
    id="customer-receive-detail-modal"
    hide-footer
    :title="
      disabled
        ? `Xem chi tiết khách hàng tiếp nhận`
        : `Cập nhật khách hàng tiếp nhận`
    "
    size="l"
  >
    <b-container>
      <b-row>
        <b-col cols="6">
          <basic-input
            class="m-0"
            v-model="form.name"
            placeholder="Nhập Họ và tên"
            :disabled="disabled"
            :required="true"
            :value.sync="form.name"
            label="Họ và Tên"
            v-validate="'required'"
            name="name"
            data-vv-as="Họ và Tên"
            :state="validateState('fullName')"
            :invalidFeedback="errors.first('fullName')"
          />
        </b-col>
        <b-col cols="6">
          <basic-input
            class="m-0"
            v-model="form.phoneNumber"
            placeholder="Nhập số điện thoại"
            :disabled="disabled"
            :required="true"
            :value.sync="form.phoneNumber"
            label="Số điện thoại"
            v-validate="'required'"
            name="phoneNumber"
            data-vv-as="Số điện thoại"
            :state="validateState('phoneNumber')"
            :invalidFeedback="errors.first('phoneNumber')"
          />
        </b-col>
        <b-col cols="6">
          <basic-select
            label="Phân loại bệnh"
            placeholder="--- Chọn phân loại ---"
            name="diseaseType"
            :options="diseasesOpts"
            :solid="false"
            :required="true"
            :allowEmpty="false"
            track-by="value"
            value-label="text"
            :disabled="disabled"
            :value="getNameOf('diseaseType', this.form.diseaseType)"
            changeValueByObject
            data-vv-as="Disease"
            @update:value="selected($event, 'diseaseType', diseasesOpts)"
          />
        </b-col>
        <b-col cols="6">
          <basic-select
            placeholder="--- Chọn coach ---"
            name="coach"
            :solid="false"
            changeValueByObject
            label="Coach phụ trách"
            :disabled="disabled"
            :value="getNameOf('coach', this.form.coachId)"
            :options="coaches"
            class="filter mb-0"
            :clear="false"
            :hideSelected="true"
            trackBy="value"
            valueLabel="text"
            @update:value="selected($event, 'coach', coaches)"
          />
        </b-col>
        <b-col cols="6">
          <basic-select
            placeholder="--- Chọn khóa ---"
            name="course"
            :solid="false"
            changeValueByObject
            label="Khóa"
            :disabled="disabled"
            :value="getNameOf('course', this.form.courseId)"
            :options="courses"
            class="filter mb-0"
            :clear="false"
            :hideSelected="true"
            trackBy="value"
            valueLabel="text"
            @update:value="selected($event, 'course', courses)"
          />
        </b-col>
        <b-col cols="6">
          <basic-input
            v-model="form.contactSource"
            placeholder="Nguồn tiếp nhận"
            :disabled="disabled"
            :value.sync="form.contactSource"
            label="Nguồn tiếp nhận"
            name="contactSource"
          />
        </b-col>
        <b-col cols="6">
          <basic-input
            v-model="form.birthYear"
            placeholder="Năm sinh"
            :disabled="disabled"
            :value.sync="form.birthYear"
            label="Năm sinh"
            name="birthYear"
          />
        </b-col>
        <b-col cols="6">
          <basic-select
            placeholder="--- Chọn giới tính ---"
            name="gender"
            :solid="false"
            changeValueByObject
            label="Giới tính"
            :disabled="disabled"
            :value="getNameOf('gender', this.form.gender)"
            :options="genders"
            :clear="false"
            :hideSelected="true"
            trackBy="value"
            valueLabel="text"
            @update:value="selected($event, 'gender', genders)"
          />
        </b-col>
        <b-col cols="12">
          <basic-input
            v-model="form.reasonOut"
            placeholder="Ghi chú"
            :disabled="disabled"
            :value.sync="form.reasonOut"
            label="Ghi chú"
            name="reasonOut"
          />
        </b-col>
      </b-row>
      <div class="d-flex justify-content-end align-items-center mt-4">
        <b-button
          class="btn btn-plain ml-2"
          href="#"
          :disabled="loading"
          @click.stop="handleCancel"
          tabindex="0"
        >
          Huỷ
        </b-button>
        <b-button
          v-if="!disabled"
          class="btn btn-success ml-3"
          @click.stop="handleSubmit"
          type="submit"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Cập nhật
        </b-button>
        <b-button
          v-if="disabled"
          class="btn btn-success ml-3"
          @click.stop="handelEdit"
          type="submit"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/edit-2.svg" />
          </span>
          Chỉnh sửa
        </b-button>
      </div>
    </b-container>
  </b-modal>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import { DISEASES } from '../CustomerReceiveEnum';
const { mapState } = createNamespacedHelpers('customerReceives');
export default {
  name: 'DetailModal',
  props: {
    targetItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        id: null,
        name: '',
        phoneNumber: null,
        gender: null,
        birthYear: null,
        status: null,
        coachId: null,
        reasonOut: null,
        diseaseType: null,
        courseId: null,
        contactSource: null,
      },
      disabled: true,
      genders: [
        {
          text: 'Nam',
          value: 0,
        },
        {
          text: 'Nữ',
          value: 1,
        },
        {
          text: 'Khác',
          value: 2,
        },
      ],
    };
  },
  methods: {
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    handleCancel() {
      this.disabled = true;
      this.$bvModal.hide('customer-receive-detail-modal');
    },
    handelEdit() {
      this.disabled = false;
    },
    handelValidation() {
      this.disabled = true;
    },
    getNameOf(key, value) {
      switch (key) {
        case 'coach':
          return this.coaches?.find((item) => item.value == value) ?? null;
        case 'diseaseType':
          return this.diseasesOpts?.find((item) => item.value == value) ?? null;
        case 'course':
          return this.courses?.find((item) => item.value == value) ?? null;
        case 'gender':
          return this.genders?.find((item) => item.value == value) ?? null;
      }
    },
    selected(value, form, optionsArr) {
      const v = value
        ? optionsArr.find((el) => el.value === value?.value)
        : null;
      switch (form) {
        case 'coach':
          this.form.coachId = v.value;
          break;
        case 'diseaseType':
          this.form.diseaseType = v.value;
          break;
        case 'course':
          this.form.courseId = v.value;
          break;
        case 'gender':
          this.form.gender = v.value;
          break;
      }
    },
    async handleSubmit() {
      var payload = {
        id: this.form.id,
        name: this.form.name,
        phoneNumber: this.form.phoneNumber,
        gender: this.form.gender,
        birthYear: this.form.birthYear,
        coachId: this.form.coachId,
        reasonOut: this.form.reasonOut,
        diseaseType: this.form.diseaseType,
        courseId: this.form.courseId,
        contactSource: this.form.contactSource,
      };
      const payloadJson = JSON.stringify(payload);
      try {
        await this.$api.put(
          `/CustomerReceives/update/${this.form.id}`,
          payloadJson,
          {
            headers: { 'Content-Type': 'application/json' },
          },
        );
        this.$toastr.s({
          title: 'Thành công !',
          msg: 'Cập nhật khách hàng thành công.',
        });
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      }
      this.$emit('load-data');
      this.$bvModal.hide('customer-receive-detail-modal');
    },
  },
  watch: {
    targetItem: {
      deep: true,
      handler(val) {
        this.form = {
          id: val.id,
          name: val.name,
          phoneNumber: val.phoneNumber,
          gender: val.gender,
          birthYear: val.birthYear,
          status: val.status,
          coachId: val.coachId,
          reasonOut: val.reasonOut,
          diseaseType: val.diseaseType,
          courseId: val.courseId,
          contactSource: val.contactSource,
        };
        this.disabled = true;
      },
    },
  },
  computed: {
    ...mapState(['coaches', 'courses']),
    diseasesOpts() {
      return [
        { text: 'Tiền đái tháo đường', value: DISEASES.TIEN_DAI_THAO_DUONG },
        { text: 'Đái tháo đường típ 1', value: DISEASES.DAI_THAO_DUONG_TIP_1 },
        { text: 'Đái tháo đường típ 2', value: DISEASES.DAI_THAO_DUONG_TIP_2 },
        {
          text: 'Đái tháo đường thai kì',
          value: DISEASES.DAI_THAO_DUONG_THAI_KI,
        },
        { text: 'Tăng huyết áp', value: DISEASES.TANG_HUYET_AP },
        { text: 'Béo phì', value: DISEASES.BEO_PHI },
        { text: 'Khác', value: DISEASES.KHAC },
      ];
    },
  },
};
</script>
